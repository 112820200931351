export type StepsKeys = "stepOne" | "stepTwo" | "stepThree";

export const steps: Record<
  StepsKeys,
  { photoCaptureCheck: string[]; gifSource: string; color: string; batch: string }
> = {
  stepOne: {
    photoCaptureCheck: [
      "Laptop closed",
      "Top facing up",
      "Parallel with alignment marker",
      "Centered on the alignment maker",
    ],
    gifSource: "/resale-photo-booth-step-1.webm",
    color: "#F2A02B",
    batch: "1",
  },
  stepTwo: {
    photoCaptureCheck: [
      "Laptop open",
      "Screen is angled 130 degrees",
      "Parallel with alignment marker",
      "Centered on the alignment maker",
    ],
    gifSource: "/resale-photo-booth-step-2.webm",
    color: "#0183B7",
    batch: "2",
  },
  stepThree: {
    photoCaptureCheck: [
      "Laptop closed and bottom is face up",
      "Back edge is facing front",
      "Parallel with alignment marker",
      "Centered on the alignment maker",
    ],
    gifSource: "/resale-photo-booth-step-3.webm",
    color: "#D58031",
    batch: "3",
  },
};

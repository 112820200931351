import { Box, Step, StepLabel, Stepper } from "@mui/material";
import CameraSetup from "components/Photobooth/CameraSetup";
import { steps, StepsKeys } from "components/Photobooth/photoBoothSteps";
import PhotoCapture from "components/Photobooth/PhotoCapture";
import PhotoReview from "components/Photobooth/PhotoReview";
import ScanInventory from "components/Photobooth/ScanInventory";
import { WebcamComponent } from "components/WebcamComponent";
import { audioSources } from "constants/audio_sources";
import { useAlert } from "hooks/useAlert";
import useInventory from "hooks/useInventory";
import useResalePhotos from "hooks/useInventoryImageResalePhotos";
import { RefObject, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useHotkeys } from "react-hotkeys-hook";
import { base64ImageToFile } from "utils/blob";

enum CaptureAngle {
  Front,
  Left,
  Right,
  Top,
  Hero,
}

const captureOpenUpright = [CaptureAngle.Hero, CaptureAngle.Top];
const captureClosedUpright = [
  CaptureAngle.Front,
  CaptureAngle.Right,
  CaptureAngle.Left,
  CaptureAngle.Top,
  CaptureAngle.Hero,
];
const captureUpsideDown = [CaptureAngle.Top, CaptureAngle.Front];
const activeConfiguration: CaptureAngle[][] = [captureClosedUpright, captureOpenUpright, captureUpsideDown];
const captureSetImageFilename = [
  "front_edge",
  "right_edge",
  "left_edge",
  "top_face",
  "top_angle",
  "hero",
  "keyboard",
  "bottom_face",
  "back_edge",
];
const localStorageKey = "resale-photo-booth/device-map";
const onCaptureAudioElement = new Audio(audioSources.onCaptureReady);

export default function ResalePhotoBoothPage() {
  const [devices, setDevices] = useState<MediaDeviceInfo[]>([]);
  const { inventory, setInventory, getInventoryById } = useInventory();
  const { uploadFiles } = useResalePhotos(inventory);
  const { alertError } = useAlert();

  const [frontDevice, setFrontDevice] = useState<MediaDeviceInfo | null>(null);
  const [leftDevice, setLeftDevice] = useState<MediaDeviceInfo | null>(null);
  const [rightDevice, setRightDevice] = useState<MediaDeviceInfo | null>(null);
  const [topDevice, setTopDevice] = useState<MediaDeviceInfo | null>(null);
  const [heroDevice, setHeroDevice] = useState<MediaDeviceInfo | null>(null);

  const frontRef = useRef<{ capture: () => string | undefined }>(null);
  const leftRef = useRef<{ capture: () => string | undefined }>(null);
  const rightRef = useRef<{ capture: () => string | undefined }>(null);
  const topRef = useRef<{ capture: () => string | undefined }>(null);
  const heroRef = useRef<{ capture: () => string | undefined }>(null);

  const [currentSet, setCurrentSet] = useState(0);
  const [photoSets, setPhotoSets] = useState<string[][]>([]);
  const [isUploading, setIsUploading] = useState(false);

  const [showConfigurationPanel, setShowConfigurationPanel] = useState(false);

  const angles = useMemo(
    () => [frontDevice, leftDevice, rightDevice, topDevice, heroDevice],
    [frontDevice, heroDevice, leftDevice, rightDevice, topDevice],
  );
  const isInventoryLoaded = useMemo(() => !!inventory, [inventory]);

  const parseCapture = (ref: RefObject<{ capture: () => string | undefined }>) => {
    const img = ref.current?.capture();
    return img as string;
  };

  const stepKeys: StepsKeys[] = ["stepOne", "stepTwo", "stepThree"];

  const handleCapture = () => {
    const set = activeConfiguration[currentSet];
    const photos = set.map((angle) => {
      switch (angle) {
        case CaptureAngle.Front:
          return parseCapture(frontRef);
        case CaptureAngle.Left:
          return parseCapture(leftRef);
        case CaptureAngle.Right:
          return parseCapture(rightRef);
        case CaptureAngle.Top:
          return parseCapture(topRef);
        case CaptureAngle.Hero:
          return parseCapture(heroRef);
      }
    });

    setPhotoSets((value) => [...value, photos]);
    setCurrentSet((value) => value + 1);
  };

  const activeStep = useMemo(() => {
    let step = 0;
    if (isInventoryLoaded) step++;
    step += photoSets.length;
    return step;
  }, [isInventoryLoaded, photoSets.length]);

  const handleReset = () => {
    setInventory(undefined);
    setPhotoSets([]);
    setCurrentSet(0);
  };

  const submitPhotos = async () => {
    setIsUploading(true);
    const newPhotos = photoSets.flat().flat();
    const files = newPhotos.flat().map((image, index) => base64ImageToFile(image, captureSetImageFilename[index]));
    uploadFiles(files)
      .then(() => {
        handleReset();
      })
      .catch((err) => {
        alertError("Failed to upload photos. Please try again.");
        console.error("Failed to upload photos:", err);
      })
      .finally(() => {
        setIsUploading(false);
      });
  };

  useHotkeys(
    "mod+g",
    () => {
      if (!!inventory) {
        if (currentSet < activeConfiguration.length) handleCapture();
        else if (!isUploading) submitPhotos();
        onCaptureAudioElement.play();
      }
    },
    { description: "Capture Photo / Confirm", enableOnFormTags: ["INPUT"], preventDefault: true },
  );

  const saveCameraMapping = () => {
    localStorage.setItem(localStorageKey, JSON.stringify(angles));
  };

  const loadCameraMapping = () => {
    const mapping = localStorage.getItem(localStorageKey);
    setFrontDevice(mapping ? JSON.parse(mapping)[0] : null);
    setLeftDevice(mapping ? JSON.parse(mapping)[1] : null);
    setRightDevice(mapping ? JSON.parse(mapping)[2] : null);
    setTopDevice(mapping ? JSON.parse(mapping)[3] : null);
    setHeroDevice(mapping ? JSON.parse(mapping)[4] : null);

    if (mapping) {
      const data = JSON.parse(mapping) as (MediaDeviceInfo | null)[];
      if (data.some((device) => device === null)) {
        setShowConfigurationPanel(true);
      }
    } else {
      setShowConfigurationPanel(true);
    }
  };

  const handleDevices = useCallback(
    (mediaDevices: MediaDeviceInfo[]) =>
      setDevices(mediaDevices.filter(({ kind }: { kind: string }) => kind === "videoinput")),
    [setDevices],
  );

  const handleEnumerateDevices = useCallback(() => {
    navigator.mediaDevices.enumerateDevices().then(handleDevices);
  }, [handleDevices]);

  useEffect(() => {
    handleEnumerateDevices();
    navigator.mediaDevices.ondevicechange = handleEnumerateDevices;
  }, [handleEnumerateDevices]);
  useEffect(loadCameraMapping, []);
  useEffect(saveCameraMapping, [angles]);

  return (
    <Box>
      <Box position="fixed" display="flex" width={0} height={0} sx={{ opacity: 0 }}>
        {frontDevice && <WebcamComponent device={frontDevice} ref={frontRef} />}
        {leftDevice && <WebcamComponent device={leftDevice} ref={leftRef} />}
        {rightDevice && <WebcamComponent device={rightDevice} ref={rightRef} />}
        {topDevice && <WebcamComponent device={topDevice} ref={topRef} />}
        {heroDevice && <WebcamComponent device={heroDevice} ref={heroRef} />}
      </Box>

      <Box>
        {showConfigurationPanel ? (
          <CameraSetup
            devices={devices}
            setShowConfigurationPanel={setShowConfigurationPanel}
            frontDevice={frontDevice}
            setFrontDevice={setFrontDevice}
            leftDevice={leftDevice}
            setLeftDevice={setLeftDevice}
            rightDevice={rightDevice}
            setRightDevice={setRightDevice}
            topDevice={topDevice}
            setTopDevice={setTopDevice}
            heroDevice={heroDevice}
            setHeroDevice={setHeroDevice}
          />
        ) : (
          <>
            <Box>
              <Stepper alternativeLabel activeStep={activeStep} sx={{ my: 4 }}>
                <Step completed={isInventoryLoaded}>
                  <StepLabel>Scan Label</StepLabel>
                </Step>

                {activeConfiguration.map((_value, index) => (
                  <Step key={index} completed={photoSets.length > index}>
                    <StepLabel>Set {index + 1}</StepLabel>
                  </Step>
                ))}

                <Step last completed={isUploading}>
                  <StepLabel>Review</StepLabel>
                </Step>
              </Stepper>
            </Box>
            {photoSets.length >= activeConfiguration.length ? (
              <PhotoReview
                photoSets={photoSets}
                isUploading={isUploading}
                handleReset={handleReset}
                submitPhotos={submitPhotos}
                filenames={captureSetImageFilename}
              />
            ) : (
              <>
                {isInventoryLoaded ? (
                  <PhotoCapture
                    handleCapture={handleCapture}
                    handleReset={handleReset}
                    photoCaptureCheck={steps[stepKeys[activeStep - 1]]?.photoCaptureCheck || []}
                    gifSource={steps[stepKeys[activeStep - 1]]?.gifSource || ""}
                    color={steps[stepKeys[activeStep - 1]]?.color || ""}
                    batch={steps[stepKeys[activeStep - 1]]?.batch || "0"}
                  />
                ) : (
                  <ScanInventory
                    getInventoryById={getInventoryById}
                    handleOpenCameraSettings={() => setShowConfigurationPanel(true)}
                  />
                )}
              </>
            )}
          </>
        )}
      </Box>
    </Box>
  );
}
